<template>
  <div class="patent row justify-center q-pb-lg q-pt-sm">
    <div class="col-12">
      <q-breadcrumbs separator='>' gutter="md" style="margin: 20px 0">
        <q-breadcrumbs-el label="检索结果列表" style="cursor: pointer" @click="$router.push('/search')" />
        <q-breadcrumbs-el :label="'专利信息'" />
    </q-breadcrumbs>
    </div>
    <q-card class="col-12">
      <q-card-section>
        <q-tabs
          v-model="tab"
          dense
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          align="left"
          narrow-indicator
        >
          <q-tab name="mails" label="基本信息"/>
          <q-tab name="movies" label="说明书全文"/>
          <q-tab name="alarms" label="法律信息"/>
          <q-tab name="claims" label="专利权利要求"/>
        </q-tabs>
        <q-separator/>
        <q-tab-panels v-model="tab" animated>
          <!-- 基本信息面板 -->
          <q-tab-panel name="mails">
            <!-- 第一行 -->
            <q-badge
                caption
                :color="setTypeColor(patent.type)"
                :label="patent.type"
                class="q-mr-sm"
            ></q-badge>
            <q-badge
                caption
                outline
                :color="setStatusColor(patent.legalStatus)"
                :label="patent.legalStatus"
            ></q-badge>
            <div class="text-h6" v-text="patent.title">Mails</div>
            <!-- 第二行 -->
            <div class="row">
              <!-- 第一列 -->
              <div class="col-9">
                <div class="row q-pb-sm q-pt-md">
                  <div class="col-2">公开(公告)号:</div>
                  <div class="col-4" v-text="patent.documentNumber"></div>
                  <div class="col-1">申请号:</div>
                  <div class="col-2" v-text="patent.applicationNumber"></div>
                </div>
                <div class="row q-pb-xs">
                  <div class="col-2">公开(公告)日:</div>
                  <div class="col-4" v-text="patent.documentDate"></div>
                  <div class="col-1">申请日:</div>
                  <div class="col" v-text="patent.applicationDate"></div>
                </div>
                <div class="row q-pb-xs">
                  <div class="col-2">申请(专利权)人:</div>
                  <div class="col-6" v-text="patent.applicant"></div>
                </div>
                <div class="row q-pb-xs">
                  <div class="col-2">发明(设计)人:</div>
                  <div class="col-6" v-text="patent.inventor"></div>
                </div>
                <div class="row q-pb-xs">
                  <div class="col-2">申请人地址:</div>
                  <div class="col-7" v-text="patent.applicantAddress"></div>
                </div>
                <div class="row q-pb-xs">
                  <div class="col-2">专利权人:</div>
                  <div class="col-6" v-text="patent.assignee"></div>
                </div>
                <div class="row q-pb-xs">
                  <div class="col-2">当前专利权人:</div>
                  <div class="col-6" v-text="patent.currentAssignee"></div>
                </div>
                <div class="row q-pb-xs">
                  <div class="col-2">代理机构:</div>
                  <div class="col-6" v-text="patent.agency"></div>
                </div>
                <div class="row q-pb-xs">
                  <div class="col-2">代理人:</div>
                  <div class="col-6" v-text="patent.agent"></div>
                </div>
                <div class="row q-pb-xs">
                  <div class="col-2">主分类号:</div>
                  <div class="col-4" v-text="patent.mainIpc"></div>
                  <div class="col-1">IPC分类号:</div>
                  <div class="col" v-text="patent.ipc"></div>
                </div>
              </div>
              <!-- 第二列 -->
              <div class="col-3">
                <img v-lazy="getImageURL(patent.imagePath)"/>
              </div>
            </div>
            <!-- 第三行 -->
            <div class="row">
              <div class="col-12">摘要:</div>
              <div class="row" v-text="patent.summary ? patent.summary : '暂无'"></div>
            </div>
          </q-tab-panel>
          <!-- 说明文全书面板 -->
          <q-tab-panel name="movies">
            <div v-for="item in desc.lines" :key="item.id">
              <strong v-if="item.isTitle">{{ item.text }}</strong>
              <label v-if="!item.isTitle">{{ item.text }}</label>
            </div>
          </q-tab-panel>
          <!-- 法律信息面板 -->
          <q-tab-panel name="alarms">
            <q-list bordered class="rounded-borders">
              <q-expansion-item
                  v-for="item in transactions"
                  :key="item.id"
                  expand-separator
                  icon="drafts"
                  :label="item.date + ': ' + item.type"
                  header-class="text-blue"
              >
                <q-card>
                  <q-card-section
                      v-text="item.content ? item.content : '暂无'"
                  ></q-card-section>
                </q-card>
              </q-expansion-item>
            </q-list>
          </q-tab-panel>
          <q-tab-panel name="claims">
          <template v-if="isLogin">
            <q-card-section   v-html="claims||'暂无'"></q-card-section>
        </template>

          <q-btn v-else class="btnLogin">
              <a style="color: #333333;"
                 href="https://trade.xjip.info/index.php?s=/index/user/unionlogin.html&backurl=https://r.xjip.info/">登录后查看</a>
            </q-btn>

          </q-tab-panel>
        </q-tab-panels>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import {getImageURL} from "@/utils/util";
export default {
  name: "Patent",
  props: {
    patentId: {
      default: ''
    }
  },
  data() {
    return {
      tab: "mails",
      patent: {},
      IMAGE_URL: "https://www.patenthub.cn",
      desc: {
        lines: [],
        raw_str: ""
      },
      transactions: [],
      claims: ""
    };
  },
  mounted() {
    // 获取传入的专利 ID 参数
    const id = this.$route.params.id
    this.getPatentDetail(id)
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin
    }
  },
  methods: {
    getImageURL,
    getPatentDetail(v) {
      if (v) {
        let _this = this;
        this.$q.loading.show();
        this.$http
            .get(process.env.VUE_APP_API_BASE_URL + "/proxier/patent?id=" + v)
            .then(response => {
              _this.$q.loading.hide();
              if (response.data.code === 20000) {
                _this.patent = response.data.base;
                _this.transactions = response.data.li;
                _this.claims = response.data.claims;
                _this.$set(_this.desc, "lines", response.data.desc);
                console.log("desc:",_this.desc.lines);
              }
            });
      }
    },
    setTypeColor(v) {
      if (v) {
        // console.log("设置类型颜色", v);
        if (v.indexOf("实用新型") != -1) {
          return "blue";
        } else if (v.indexOf("发明公开") != -1) {
          return "purple";
        } else if (v.indexOf("发明授权") != -1) {
          return "green";
        }
      }
    },
    setStatusColor(v) {
      if (v) {
        // console.log("设置状态颜色", v);
        if (v.indexOf("失效") != -1 || v.indexOf("无效") != -1) {
          return "grey";
        } else {
          return "secondary";
        }
      }
    }
  },
  watch: {
    patentId: {
      handler: function (val) {
        if (val) {
          this.getPatentDetail(this.patentId)
        }
      },
      immediate: true
    }
  }
};
</script>
<style scoped>
.btnLogin {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  /*background-color: #9C27B0;*/
}
</style>